import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"

import SingleColumnBlock from "@components/singleColumnBlock"

import {
  RequestMoreInfo,
} from "@components/Forms"

const NotFoundPage = () => (
  <PageLayout>
    <SEO title="404 | Page Not Found | Striking Kooks with Jimmy Ether" />
    <SingleColumnBlock
      textPadded
      text={
        <div>
          <h1>What're you? Some kinda kook?</h1>
          <p>We don't have that page you were looking for here. Go to the <a href="/">homepage</a>. That's where you can find the podcast episodes.</p>
          <h4>Or tell us where you are and what you want. We will try to help.</h4>
          <RequestMoreInfo />
        </div>
      }
    />
  </PageLayout>
)

export default NotFoundPage
